@import "~bootstrap/scss/bootstrap"
@import "~rfs/sass"

body, h1, h2, p
  font-family: 'Aileron', sans-serif
  letter-spacing: 1px

.header-section
  display: flex
  justify-content: center
  align-items: center
  background-color: white
  +padding(1.25rem 0)

.header-logo
  width: 10rem
  @media (max-width: 768.98px) 
    width: 8rem

.banner,
.img-bloc
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  height: 100vh
  @media (max-width: 768.98px) 
    height: 50vh

.banner-content
  text-align: center
  color: white
  text-shadow: -3px 3px 5px rgba(0, 0, 0, 0.35)
  +padding(0 1rem)

.bloc-content
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  height: 100%
  +padding(4rem 0)

.bloc-content-sm
  +padding(1rem 0)  

.bloc-content-primary
  background-color: #24243f
  color: white

.bloc-content-secondary
  background-color: #ff99b3
  color: white

.icon
  width: 4rem
  box-shadow: -5px 5px 10px rgba(0,0,0, 15%)
  border-radius: 50%
  margin: 0 1rem

.link
  text-decoration: none
  color: lighten(#ff99b3, 10%) 
  transition: all .5s cubic-bezier(.39,.58,.57,1)
  font-weight: 700
  &:hover
    color: #ff99b3

.footer-link
  color: lighten(#24243f,33%)
  &:hover
    color: lighten(#24243f,66%)

.link-icon
  display: inline-block
  transform: scale(1)
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1)
  &:hover
    transform: scale(1.1)

.primary-heading
  font-weight: 700
  +font-size(2.25rem)

.secondary-heading
  font-weight: 300
  +font-size(1.5rem)  
  @media (max-width: 768.98px) 
    +font-size(1rem)  

.heading-with-bg
  display: flex
  justify-content: center
  align-items: center
  background-color: rgb(36 36 63 / 66%)
  color: white
  border-radius: 2rem
  +padding(.5rem 1rem)

.content
  font-weight: 400
  +font-size(1.25rem)
  text-align: center
  @media (max-width: 768.98px) 
    font-size: 1rem

.content-sm
  +font-size(1rem)
  @media (max-width: 768.98px) 
    font-size: .85rem
      
.footer-section
  text-align: center
  background-color: #24243f
  +padding(2rem 0)
  color: white

li
  list-style: none
  +margin-bottom(3rem)  

li:last-child
  margin-bottom: 0

.list-item-title
  font-weight: 700
  text-transform: uppercase
  display: block
  +font-size(1rem)
  color: lighten(#24243f,33%)
  +margin-bottom(.5rem)
  @media (max-width: 768.98px)  
    +font-size(.95rem)

.list-item-title-light
  color: lighten(#24243f,33%)
  +font-size(.85rem)
  text-transform: none 

.text-underline
  text-decoration: underline

.eshop-banner-section
  height: 100vh
  background-image: url(../../public/images/banniere-eshop.jpg)
  background-repeat: no-repeat
